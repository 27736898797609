import request from '../utils/request';

let basePath = process.env.VUE_APP_BASE_PATH;

/**
 * 查询会员信息
 * @param searchParam
 * @param pageIndex
 * @param pageSize
 */
export async function getMemberInfoList(searchParam) {
  try {
    const res = await request.get('/car/member-info/list/page', JSON.parse(searchParam));
    return Promise.resolve(res);
  } catch (err) {
    return Promise.reject(err);
  }
}

/**
 * 新增会员信息
 * @param searchParam
 * @param pageIndex
 * @param pageSize
 */
export async function addMember(memberInfo) {
  try {
    const res = await request.post('/car/member-info/add', JSON.parse(memberInfo));
    return Promise.resolve(res);
  } catch (err) {
    return Promise.reject(err);
  }
}

/**
 * 查看黑名单列表
 * @param searchParam
 */
export async function getBlackMemberList(searchParam) {
    try {
        const res = await request.get('/car/member-info/list/pageBlack', JSON.parse(searchParam));
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
}

/**
 * 编辑会员信息
 * @param memberInfo
 */
export async function editMemberInfo(memberInfo) {
  try {
    const res = await request.put('/car/member-info/edit', JSON.parse(memberInfo));
    return Promise.resolve(res);
  } catch (err) {
    return Promise.reject(err);
  }
}

/**
 * 导入会员信息
 * @param parkId
 */
export async function importMemberInfo(parkId) {
  try {
    const res = await request.post('/car/member-info/import/'+parkId);
    return Promise.resolve(res);
  } catch (err) {
    return Promise.reject(err);
  }
}

/**
 * 查询会员类型信息
 * @param key
 * @param pageIndex
 * @param pageSize
 */
export async function getMemberInfoTypeList(pageParam) {
  try {
    const res = await request.get('/car/memberInfoType/page', JSON.parse(pageParam)
    );
    return Promise.resolve(res);
  } catch (err) {
    return Promise.reject(err);
  }
}

/**
 * 新增会员类型
 * @param parkId
 */
export async function addMemberInfoType(memberinfoType) {
  try {
    const res = await request.post('/car/memberInfoType/add',JSON.parse(memberinfoType));
    return Promise.resolve(res);
  } catch (err) {
    return Promise.reject(err);
  }
}

/**
 * 修改会员类型
 * @param parkId
 */
export async function updateMemberInfoType(memberinfoType) {
  try {
    const res = await request.put('/car/memberInfoType/update',JSON.parse(memberinfoType));
    return Promise.resolve(res);
  } catch (err) {
    return Promise.reject(err);
  }
}

/**
 * 删除会员类型
 * @param parkId
 */
export async function deleteMemberInfoType(typeId) {
  try {
    const res = await request.delete('/car/memberInfoType/delete/'+typeId);
    return Promise.resolve(res);
  } catch (err) {
    return Promise.reject(err);
  }
}

/**
 * 查询在场车辆信息
 * @param parkId
 */
export async function getPresentCarInfo(searchParam) {
  try {
    const res = await request.get('/car/presentCarInfo/page',JSON.parse(searchParam));
    return Promise.resolve(res);
  } catch (err) {
    return Promise.reject(err);
  }
}

/**
 * 导入在场车辆信息
 * @param parkId
 */
export async function importCarInfo(parkId) {
  try {
    const res = await request.post('/car/presentCarInfo/import/'+parkId);
    return Promise.resolve(res);
  } catch (err) {
    return Promise.reject(err);
  }
}

/**
 * 查询岗亭收入明细信息
 * @param parkId
 */
export async function getSentryIncomeInfoList(searchParam) {
  try {
    const res = await request.get('/car/sentry-income-info/list/page',JSON.parse(searchParam));
    return Promise.resolve(res);
  } catch (err) {
    return Promise.reject(err);
  }
}

/**
 * 导入岗亭收入明细信息
 * @param parkId
 */
export async function importSentryIncomeInfo(parkId) {
  try {
    const res = await request.post('/car/sentry-income-info/import/'+parkId);
    return Promise.resolve(res);
  } catch (err) {
    return Promise.reject(err);
  }
}

/**
 * 查询停车场信息
 * @param parkId
 */
export async function getCarParkInfoList(searchParam) {
  try {
    const res = await request.get('/parking/parking-info/list/page',JSON.parse(searchParam));
    return Promise.resolve(res);
  } catch (err) {
    return Promise.reject(err);
  }
}

/**
 * 编辑停车场信息
 * @param parkId
 */
export async function editParkingInfo(parkingInfo) {
  try {
    const res = await request.putJson('/parking/parking-info/edit',JSON.parse(parkingInfo));
    return Promise.resolve(res);
  } catch (err) {
    return Promise.reject(err);
  }
}

/**
 * 新增停车场信息
 * @param parkId
 */
export async function addParkingInfo(parkingInfo) {
  try {
    const res = await request.postJson('/parking/parking-info/add',JSON.parse(parkingInfo));
    return Promise.resolve(res);
  } catch (err) {
    return Promise.reject(err);
  }
}

/**
 * 查询导入记录
 * @param parkId
 */
export async function getImportRecordList(pageParam) {
  try {
    const res = await request.get('/car/importRecord/page',JSON.parse(pageParam));
    return Promise.resolve(res);
  } catch (err) {
    return Promise.reject(err);
  }
}

/**
 * 下载记录表
 * @param parkId
 */
export async function downLoadRecordFile(fileName,ids) {
  try {
    const res = await request.download('/car/importRecord/file',{
        ids:ids
    },fileName);
    return Promise.resolve(res);
  } catch (err) {
    return Promise.reject(err);
  }
}

/**
 * 查询后台用户
 * @param parkId
 */
export async function getAuthUserList(pageParam) {
  try {
    const res = await request.get('/car/authUser/page',JSON.parse(pageParam));
    return Promise.resolve(res);
  } catch (err) {
    return Promise.reject(err);
  }
}

/**
 * 新增后台用户
 * @param parkId
 */
export async function addAuthUser(pageParam) {
  try {
    const res = await request.postJson('/car/authUser/add',JSON.parse(pageParam));
    return Promise.resolve(res);
  } catch (err) {
    return Promise.reject(err);
  }
}

/**
 * 修改后台用户
 * @param parkId
 */
export async function updateAuthUser(pageParam) {
  try {
    const res = await request.putJson('/car/authUser/update',JSON.parse(pageParam));
    return Promise.resolve(res);
  } catch (err) {
    return Promise.reject(err);
  }
}

/**
 * 修改后台用户密码
 * @param password 参数1
 * @param confirmPass 参数1
 */
export async function updateAuthUserPassWord(pageParam) {
  try {
    const res = await request.put('/car/authUser/password',JSON.parse(pageParam));
    return Promise.resolve(res);
  } catch (err) {
    return Promise.reject(err);
  }
}

/**
 * 删除后台用户
 */
export async function deleteAuthUser(userId) {
  try {
    const res = await request.delete('/car/authUser/'+ userId);
    return Promise.resolve(res);
  } catch (err) {
    return Promise.reject(err);
  }
}



/**
 * 查询后台角色
 * @param pageParam
 */
export async function getAuthRoleList(pageParam) {
  try {
    const res = await request.get('/car/authRole/page',JSON.parse(pageParam));
    return Promise.resolve(res);
  } catch (err) {
    return Promise.reject(err);
  }
}

/**
 * 新增后台角色
 * @param parkId
 */
export async function addAuthRole(pageParam) {
  try {
    const res = await request.postJson('/car/authRole/add',JSON.parse(pageParam));
    return Promise.resolve(res);
  } catch (err) {
    return Promise.reject(err);
  }
}

/**
 * 修改后台角色
 * @param parkId
 */
export async function updateAuthRole(pageParam) {
  try {
    const res = await request.putJson('/car/authRole/update',JSON.parse(pageParam));
    return Promise.resolve(res);
  } catch (err) {
    return Promise.reject(err);
  }
}


/**
 * 删除后台角色
 */
export async function deleteAuthRole(roleId) {
  try {
    const res = await request.delete('/car/authRole/delete/'+ roleId);
    return Promise.resolve(res);
  } catch (err) {
    return Promise.reject(err);
  }
}

/**
 * 查询抄单记录
 * @param searchParam
 * @param pageIndex
 * @param pageSize
 */
export async function getCopyRecordList(searchParam) {
    try {
        const res = await request.get('/car/copy-order-record/list/page', JSON.parse(searchParam));
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
}
/**
 * 新增抄单记录时根据车牌号查询车辆信息
 * @param carNo
 */
export async function getMemberInfoByCarNo(searchParam) {
    try {
        const res = await request.get('/car/member-info/getMemberInfoByCarNo', JSON.parse(searchParam));
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
}
/**
 * 新增抄单记录
 * @param memberId
 */
export async function addCopyOrderRecord(copyOrderRecord) {
    try {
        const res = await request.postJson('/car/copy-order-record/add',copyOrderRecord);
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
}
/**
 * 新增抄单上传图片
 * @param parkId
 */
export async function uploadCopyImg() {
    try {
        const res = await request.post('/car/copy-order-record/uploadImg');
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
}
/**
 * 加入黑名单
 * * @param id 会员id
 * * @param blackReason
 * * @param blackLevel
 */
export async function addToBlack(addBlackInfo) {
    try {
        const res = await request.post('/car/member-info/addToBlack',addBlackInfo);
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
}
/**
 * 移出黑名单
 * * @param id 会员id
 */
export async function removeBlack(id) {
    try {
        const res = await request.post('/car/member-info/removeBlack?id=' + id,
            {});
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
}

/**
 * 查询后台权限
 * @param pageParam
 */
export async function getPermissionList(state) {
  try {
    const res = await request.get('/car/authPermission/list',{
      state:state
    });
    return Promise.resolve(res);
  } catch (err) {
    return Promise.reject(err);
  }
}

/**
 * 首页查询车辆进场数据
 * @param pageParam
 */
export async function getEntryCarList(param) {
  try {
    const res = await request.get('/car/presentCarInfo/entryCar',JSON.parse(param));
    return Promise.resolve(res);
  } catch (err) {
    return Promise.reject(err);
  }
}

/**
 * 首页查询车辆进场数据
 * @param pageParam
 */
export async function getCarStatisticsByTime(param) {
  try {
    const res = await request.get('/car/presentCarInfo/statistics',JSON.parse(param));
    return Promise.resolve(res);
  } catch (err) {
    return Promise.reject(err);
  }
}

/**
 * 首页查询车辆出场数据
 * @param pageParam
 */
export async function getSentryCarStatistics(param) {
  try {
    const res = await request.get('/car/sentry-income-info/sentryCar',JSON.parse(param));
    return Promise.resolve(res);
  } catch (err) {
    return Promise.reject(err);
  }
}

/**
 * 客户数据分析
 * @param pageParam
 */
export async function getDurationAndTotalLoss(param) {
  try {
    const res = await request.get('/car/sentry-income-info/duration',JSON.parse(param));
    return Promise.resolve(res);
  } catch (err) {
    return Promise.reject(err);
  }
}

/**
 * 过夜车辆统计-当前时间
 */
export async function getOvernightCarInfoByOneDay(param) {
  try {
    const res = await request.get('/car/presentCarInfo/overNight',JSON.parse(param));
    return Promise.resolve(res);
  } catch (err) {
    return Promise.reject(err);
  }
}

/**
 * 过夜车辆统计-时间段
 */
export async function getOvernightCarInfoByCondition(param) {
  try {
    const res = await request.get('/car/presentCarInfo/overNight/time',JSON.parse(param));
    return Promise.resolve(res);
  } catch (err) {
    return Promise.reject(err);
  }
}

/**
 * 过夜车辆统计-导出会员信息
 */
export async function exportMemberInfoOverOneDay(param) {
  try {
    const res = await request.download('/car/sentry-income-info/export',JSON.parse(param),"停车超过24小时车辆会员信息.xlsx");
    return Promise.resolve(res);
  } catch (err) {
    return Promise.reject(err);
  }
}

/**
 * 客户数据分析
 * @param pageParam
 */
export async function getParkMoreThanOneDayMemberInfo(param) {
  try {
    const res = await request.get('/car/sentry-income-info/member',JSON.parse(param));
    return Promise.resolve(res);
  } catch (err) {
    return Promise.reject(err);
  }
}

/**
 * 停车少于半小时
 * @param pageParam
 */
export async function getLessThanHalfHourByTime(param) {
  try {
    const res = await request.get('/car/sentry-income-info/halfHour',JSON.parse(param));
    return Promise.resolve(res);
  } catch (err) {
    return Promise.reject(err);
  }
}

/**
 * 区域统计分析--车辆进出场情况
 * @param param
 */
export async function getCarPassingByOneDay(param) {
  try {
    const res = await request.get('/car/presentCarInfo/carPassing/oneDay',JSON.parse(param));
    return Promise.resolve(res);
  } catch (err) {
    return Promise.reject(err);
  }
}

/**
 * 区域统计分析--数值统计
 * @param param
 */
export async function getNumericalStatistics(param) {
  try {
    const res = await request.get('/car/sentry-income-info/numerical',JSON.parse(param));
    return Promise.resolve(res);
  } catch (err) {
    return Promise.reject(err);
  }
}

/**
 * 区域统计分析--车辆停车率
 * @param param
 */
export async function getParkingRateByCondition(param) {
  try {
    const res = await request.get('/car/presentCarInfo/parkingRate',JSON.parse(param));
    return Promise.resolve(res);
  } catch (err) {
    return Promise.reject(err);
  }
}

/**
 * 区域统计分析--车辆进出场情况--时间段
 * @param param
 */
export async function getCarEntryStatistics(param) {
  try {
    const res = await request.get('/car/presentCarInfo/carPassing/times',JSON.parse(param));
    return Promise.resolve(res);
  } catch (err) {
    return Promise.reject(err);
  }
}

/**
 * 区域统计分析--车辆进出场情况--时间段
 * @param param
 */
export async function getCarOutStatisticsByCondition(param) {
  try {
    const res = await request.get('/car/sentry-income-info/carOutStatis',JSON.parse(param));
    return Promise.resolve(res);
  } catch (err) {
    return Promise.reject(err);
  }
}

/**
 * 区域统计分析--停车场收支金额
 * @param param
 */
export async function getAmountStatisticsByCondition(param) {
  try {
    const res = await request.get('/car/sentry-income-info/amountStatistics',JSON.parse(param));
    return Promise.resolve(res);
  } catch (err) {
    return Promise.reject(err);
  }
}

export default {
  basePath
}
